import { useState, useEffect } from "react";
import demo from '../assets/online_demo_video.webm';
import axios from 'axios';
import './styles.scss'

const Main = () => {

    const [filter, setFilter] = useState(false);
    const [zoomSrc, setZoomSrc] = useState(null);
    const [checkItems, setCheckItems] = useState([]);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedCount, setLoadedCount] = useState(0);
    const [showVideoPlayer, setShowVideoPlayer] = useState(false);

    const hostname = window.location.hostname;
    const port = window.location.port;
    const hostname_regex = /^localhost+/;
    const url = hostname_regex.test(hostname) ? "http://192.168.31.123:8000" : `//${hostname}:${port}`;

    const getCheckItems = () => {
        axios.get(`${url}/api/datasets`)
        .then((res) => {
            setCheckItems(res.data);
        })
        .catch((err) => {
            console.log(err)
        })
    };

    const getPhotos = (name) => {
        setIsLoading(true)
        setLoadedCount(0)
        axios.get(`${url}/api/datasets/${name}`)
        .then((res) => {
            setPhotoUrls(res.data);
        })
        .catch((err) => {
            console.log(err);
        })
    };

    useEffect(() => {
        getPhotos('SHIFT');
        getCheckItems();
    }, []);

    useEffect(() => {
        if (photoUrls.length > 0 && loadedCount === photoUrls.length) {
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        };
    }, [loadedCount, photoUrls]);

    const handleImageLoad = () => {
        setLoadedCount((prev) => prev + 1);
    };

    const photoOnClick = (src) => {
        setFilter(true);
        setZoomSrc(src);
    };

    const closeFilter = () => {
        if (showVideoPlayer) {
            let video = document.getElementById('demo_video');
            video.pause();
            video.currentTime = 0;
            setShowVideoPlayer(false)
        };
        setFilter(false);
        setZoomSrc(null);
    };

    const demoChecker = () => {
        setFilter(true);
        setShowVideoPlayer(true);
    };

    return (
        <div className="main_wrap">
            {isLoading &&
                <div className="loading-animation">
                    <div className="spinner" ></div>
                    <p>Preparing Photos...</p>
                </div>
            }
            <div className={filter ? "main_photo_detail-open" : "main_photo_detail-close"}>
                {!showVideoPlayer && <img src={zoomSrc} alt="selected-photo" />}
                {showVideoPlayer && <video id="demo_video" controls loop>
                    <source src={demo} type="video/webm" />
                    </video>}
                <div className="main_filter_close" onClick={(e) => {e.stopPropagation(); closeFilter();}}>
                    <span>X</span>
                </div>
            </div>
            <div className="main_header">
                <div className="logo">
                    <svg id="lanner" data-name="lanner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 600">
                        <path className="cls-1" d="M418.26,465.87c-1.62.08-2.94.19-4.26.19-43.47,0-86.95.08-130.42,0-15.13,0-29.14-4.06-40.43-14.53-13.55-12.57-18.55-28.27-14.09-46.3,4.51-18.21,17-29.05,34.62-33.83a83.86,83.86,0,0,1,20.72-2.54c25.82-.28,51.64-.11,77.46-.11h6.22c0-7.05.61-13.72-.31-20.18-.35-2.47-3.84-5.26-6.54-6.41-3.06-1.31-6.84-1.23-10.3-1.24-24-.09-48-.22-72,.05-7.9.08-13.35-2.78-17.06-9.71s-7.74-13.51-12.41-21.6c7.13,0,13,0,18.87,0,36.64.11,73.28.17,109.92.45a58.11,58.11,0,0,1,13.78,2c16.86,4.27,26.33,15.09,26.52,32.43.45,39.8.15,79.61.13,119.42A10.37,10.37,0,0,1,418.26,465.87Zm-50.42-31V400.68a16.6,16.6,0,0,0-2.62-.48c-22.83-.07-45.66-.4-68.47,0a37.59,37.59,0,0,0-16.61,4.46c-10.62,5.78-10.81,18.38-.95,25.39,5.51,3.92,11.86,5,18.49,5q32.49,0,65,0C364.28,435.1,365.9,435,367.84,434.84Z"/>
                        <path className="cls-1" d="M508.14,466.06H454.47V310.52a10.56,10.56,0,0,1,2.56-.61c43.47.06,86.94-.06,130.41.38a68.24,68.24,0,0,1,34.78,9.62c11.74,7,18.47,17.5,18.58,31.17.31,37.48.11,75,.11,112.44,0,.65-.13,1.29-.24,2.36H587.08v-5.65c0-31.65-.39-63.31.16-95,.28-15.7-10.15-23.85-21.63-25.35-7.38-1-14.93-.8-22.42-.88-11.47-.13-23,0-35.05,0Z"/>
                        <path className="cls-1" d="M732.08,466H678.55V310.42a10.13,10.13,0,0,1,2.26-.5c42.16,0,84.32-.17,126.48.13a72.81,72.81,0,0,1,36.76,10c13.87,8.14,21.26,19.88,21.12,36.45-.28,35-.08,70-.09,105,0,1.31-.13,2.63-.22,4.4H811.17v-5.6q0-46.5,0-93c0-17.79-8.48-27.29-26.49-28-16.3-.68-32.66-.12-49-.28-4,0-3.59,2.45-3.59,5q0,28.5,0,57Z"/>
                        <path className="cls-1" d="M975.08,403.93l53.4-65.38a36.51,36.51,0,0,0-4.5-.46c-15.29.58-30.83-.34-45.79,2.2-19.59,3.32-33,15.56-37.38,35.65-3.91,17.86,4.53,36.07,20.93,46.73,13.31,8.65,28.17,11.74,43.77,11.8,23.67.08,47.34,0,71,0,3,0,5.91.26,9.07.42v30.65a31,31,0,0,1-3.84.48c-30.83-.09-61.67.34-92.49-.49-24.69-.67-48.26-6.58-68.46-21.75-19.88-14.92-31.92-34.54-31.59-59.95.35-26.38,13.72-45.74,35.46-59.58,16-10.18,33.9-14.85,52.67-15.26,27.15-.59,54.32-.59,81.47-.45a54.59,54.59,0,0,1,16.62,2.91c8.33,2.75,12.07,9.33,9.52,17.71a59.48,59.48,0,0,1-9,18.13c-12.24,16.23-25.22,31.88-37.81,47.84-4.89,6.21-10.82,9.16-19,8.9C1004.89,403.6,990.59,403.93,975.08,403.93Z"/>
                        <path className="cls-1" d="M211.94,430.37v35.51a13.11,13.11,0,0,1-2.82.61c-30.65-.21-61.32-.05-91.95-.95-9-.27-18.14-2.71-26.71-5.65C75.09,454.6,66.54,443,64.14,427a78.44,78.44,0,0,1-.91-11.45q-.09-77.24,0-154.48c0-1.45.18-2.91.29-4.66h54.61v6q0,68.25,0,136.48c0,23.75,7.72,31.46,31.41,31.46h62.43Z"/>
                        <path className="cls-1" d="M1237.42,309.61c-5.76,9.16-10.5,17.16-15.73,24.82-2.72,4-7.19,4.95-12,4.91-7.49-.06-15-.3-22.46.46-13.39,1.38-21.33,10.8-21.33,24.21q0,48,0,96v5.73h-54.31v-6.66c0-33.16.56-66.34-.17-99.49-.58-26.1,16.43-41.1,34.91-46.38a71.59,71.59,0,0,1,17.65-3.08c20.32-.49,40.65-.39,61-.5C1228.65,309.59,1232.3,309.61,1237.42,309.61Z"/>
                    </svg>
                </div>
                <div className="select">
                    <select name="items" id="select" onChange={(e) => {e.stopPropagation(); getPhotos(e.target.value)}}>
                        <option disabled value="null">-- Check Items --</option>
                        {checkItems && checkItems.map((c, i) => (
                            <option key={i} value={c}>{c}</option>
                        ))}
                    </select>
                </div>
                <div className="check_video" onClick={(e) => {e.stopPropagation(); demoChecker();}}>
                    <span>Check Demo Video</span>
                </div>
            </div>
            <div className="main_photos_wrap">
                {photoUrls.map((p, index) => (
                    <img
                        key={index}
                        src={p.url}
                        alt={`Photo ${index + 1}`}
                        onClick={(e) => {e.stopPropagation(); photoOnClick(e.target.src)}}
                        onLoad={handleImageLoad}
                    />
                ))}
            </div>
            <div className="main_bottom">
                <span>Lanner Smart Factory.</span><span>|</span><span>© Copyright 2024 Lanner.</span><span>All Rights Received.</span>
            </div>
        </div>
    );
}

export default Main;
